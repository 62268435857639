//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UiTileText',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    parentIndex: {
      type: Number,
      default: null,
    },
  },
  computed: {
    heading() {
      switch(this.parentIndex) {
        case 0:
          return 'h2';
        default:
          return 'h3';
      }
    },
  },
}
