var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"tile-text -g-d-f -g-fd-c -g-jc-sb",class:[
    _vm.blok.theme,
    _vm.blok.size,
    {
      'hide-mob': _vm.blok.hide_for_mobile,
      'right': _vm.blok.align_right,
    }
  ]},[(_vm.blok.title)?_c(_vm.heading,{tag:"component",staticClass:"tile-text__title -h2 -ws-pl",domProps:{"innerHTML":_vm._s(_vm.blok.title)}}):_vm._e(),_vm._v(" "),(_vm.blok.description)?_c('p',{staticClass:"tile-text__descr -t4-500 -ws-pl",domProps:{"innerHTML":_vm._s(_vm.blok.description)}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }